#StartMenu {
    position: absolute;
    bottom: 3vh;
    left: 6px;
    background-color: #bfc1c0;
    border-top: solid #e0e0e0 3px;
    border-left: solid #e0e0e0 3px;
    border-bottom: solid black 3px;
    border-right: solid black 3px;
}

#InnerStartMenu {
    border-top: white solid 3px;
    border-left: white solid 3px;
    border-right: #808080 solid 3px;
    border-bottom: #808080 solid 3px;
}