.loading-bar {
    position: fixed;
    top: 70%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    border-color: white;
    border-width: 2px;
    border-style: solid;
    width: 8vw;
    height: 2vh;
}

.loading-bar__progress {
    background-color: white;
    height: 100%;
}