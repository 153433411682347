#ContactMe {
    width: 100%;
    height: 100%;
    background-color: #c0c0c0;
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.ContactMeField {
    margin-bottom: 5px;
}

.ContactMeField p {
    margin: 0;
    display: inline-block;
    width: 30%;
    padding-left: 15px;
    box-sizing: border-box;
}

.ContactMeField input {
    margin: 0;
    display: inline-block;
    width: 65%;
    border-top: #7e7e90 solid 2px;
    border-left: #7e7e90 solid 2px;
    box-sizing: border-box;
}

#ContactMeEditor {
    border-top: white outset 3px;
    border-left: white outset 3px;
    border-bottom: black outset 3px;
    border-right: black outset 3px;
    margin: 15px;
    margin-top: 15px;
    padding: 5px;
    flex-grow: 1;
}

#ContactMe textarea {
    width: 100%;
    height: 100%;
    border-top: #7e7e90 solid 2px;
    border-left: #7e7e90 solid 2px;
    box-sizing: border-box;
    resize: none;
}

textarea:focus, input:focus{
    outline: none;
}

#ContactMeSubmit {
    width: 94%;
    border-top: white outset 3px;
    border-left: white outset 3px;
    border-bottom: black outset 3px;
    border-right: black outset 3px;
}

