#BIOS {
    height: 100%;
    width: 100%;
    background-color: black;
}

#bios-label {
    color: blue;
    font-size: 5vw;
    text-align: center;
    margin: 10;
    position: fixed;
    top: 30%;
    width: 100%;
    font-family: "VT323", monospace;
}

#bios-options {
    color: white;
    font-size: 1vw;
    text-align: left;
    position: fixed;
    top: 0;
    right: 20px;
    font-family: "VT323", monospace;
}

#bios-domain {
    color: white;
    font-size: 1vw;
    text-align: left;
    position: fixed;
    top: 50%;
    width: 100%;
    text-align: center;
    font-family: "VT323", monospace;
}