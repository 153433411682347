.window {
    border: #c4c4c4 5px ridge;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.window-head {
    background-color: #010080;
    color: white;
    border-bottom: #c4c4c4 5px ridge;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.window-head-id {
    display: flex;
    align-items: center;
}
.window-body {
    height: 100%;
    overflow: hidden;
}

.window-head-id p {
    margin: 0px;
}

.window-head-id img {
    width: 17px;
    height: 17px;
    padding-right: 3px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.navbar-buttons {
    display: flex;
    flex-direction: row;
}

#ContactMeSubmit {
    width: 95%;
    margin-top: 10px;
}
