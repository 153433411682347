.start-menu-entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
}

.start-menu-entry img {
    height: 35px;
}

.start-menu-entry p {
    padding: 5px;
}