#OS {
    height: 100%;
    width: 100%;
    background: url(/src/assets/wallpaper.png);
    background-size: cover;
}

#loading {
    height: 100%;
    width: 100%;
    background-color: #008484;
    display: flex;
    justify-content: center;
    align-items: center;
}

#startup-img {
    height: 35%;
    animation: spin 2s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

#startup-window-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#taskbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #bfc1c0;
    height: 35px;
    border-top: solid white 3px;
    display: flex;
}

#start-button {
    background-color: #c3c3c3;
    border-top: #fdfdfd 2px solid;
    border-left: #fdfdfd 2px solid;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    margin: 3px;
    user-select: none;
    cursor: pointer;
}

#start-button:active {
    border-top: #fdfdfd 2px inset;
    border-left: #fdfdfd 2px inset;
    border-bottom: black 2px inset;
    border-right: black 2px inset;
}

#start-button div {
    border-right: #8b8b8b 2px solid;
    border-bottom: #8b8b8b 2px solid;
    height: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

#start-button div p {
    margin: 0;
}

#start-button div img {
    height: 80%;
    padding-right: 5px;
}

#desktop {
    position: absolute;
    top: 0;
    bottom: 3vh;
    left: 0;
    right: 0;
    overflow: hidden;
}
