.desktop-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    flex-direction: column;
    color: white;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    width: 105px; /*so they're aligned*/
}

.desktop-icon p {
    margin: 1px;
}

.desktop-icon img {
    width: 30px;
    height: 30px;
}
