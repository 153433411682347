.taskbar-icon {
    border-top: white solid 3px;
    border-left: white solid 3px;
    border-bottom: black solid 3px;
    border-right: black solid 3px;
    margin: 3px;
    user-select: none;
    cursor: pointer;
}

.taskbar-icon div {
    border-bottom: #7e7e90 solid 3px;
    border-right: #7e7e90 solid 3px;
    min-width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
}

.taskbar-icon div img {
    height: 15px;
    margin-right: 5px;
}

.taskbar-icon.selected {
    border-top: black solid 3px;
    border-left: black solid 3px;
    border-bottom: white solid 3px;
    border-right: white solid 3px;
}

.taskbar-icon.selected div {
    border-top: #7e7e90 solid 3px;
    border-left: #7e7e90 solid 3px;
}